* {
    box-sizing: border-box;
  }
  
  body,
  h1,
  h2 {
    margin: 0;
    padding: 0;
  }

  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica , Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
  }

  button {
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    
  }

  

  /* NAV */
  .navbar {
   
    background-color: #FAFAFA;
    border-bottom: .5px solid #EBEBEB;
    padding-bottom: 10px;
}

.main-nav {
    list-style-type: none;
    display: none;
}

.nav-links,
.logo {
    text-decoration: none;
    color: black;
}

.main-nav li {
    text-align: center;
    margin: 15px auto;
}

.logo {
    display: inline-block;
    font-size: 22px;
    margin-top: 10px;
    margin-left: 20px;
}

.navbar-toggle {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
}

.active {
    display: block;
}


.breadcrumb-div{
  padding:10px;
  color: #555555;
}


.about-shop-div h5 {
  font-size: .8rem;
} 

.filter-bar{
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 0;

  
  
 
}
  
  /* Header */
  /* background: #FDFDFD; 
   background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/linjer.jpg');
   background: linear-gradient(to right, rgba(71, 71, 71, 0.5), rgba(56, 56, 56, 0.5)), url('./assets/linjer.jpg');
     
   .header-text-div {

    display: flex;
    flex-direction: column;
    align-items: center;
    color: #21243d;
    opacity: 90%;
    width: 332px;
    height: auto;
    justify-content: center;
  
   
  }
  .header {
    align-items: left;
    background: linear-gradient(to right, rgba(83, 83, 83, 0.5), rgba(128, 128, 128, 0)), url('https://cdn.shopify.com/s/files/1/0236/4195/files/DSCF3934-Edit_San-Francisco-DESK_2560x1440.jpg?v=1577122016%22'); 
    background-position: center;
    justify-content: space-between;
    background-size: cover;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    min-height: 40vh;
    text-align: center;
    border-bottom: 8px solid #ffffff;
    align-items: center;
    
  }
 
  .header-shop-text {
    padding:20px;
    
    
    margin-left: 30px;
  
  }
 
  
 
  .header-title {
    font-size: 38px;
    font-weight: 500;

  }
  .header-sub {
    font-size: 38px;
    font-weight: 500;
    color: rgb(133, 133, 133);

  }

  .header-image {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    
  }

  .header-div {
    
    display: flex;
    justify-content: center;
    align-items: center;
   

    
   
  }
  .header-text-div {

    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    opacity: 90%;
    width: 332px;
    height: auto;
    justify-content: center;
  
   
  }

  .header-img-div {

    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    opacity: 90%;
    width: 332px;
    height: auto;
    justify-content: center;
  
   
  }
  .header-img-container {
    align-items: center;
    display: flex;
    height: 200px;
    border-radius: 10px;
    justify-content: center;
    margin: auto;
    width: 300px;
  }

  .h1-header {
    min-width: 100%;
    margin: 0;

  }

  .header-text {
    font-size: 38px;
    font-weight: 500;
    color: rgb(133, 133, 133);

  }
  
  
  */
  
  
  
  
  

  
  
  
  

  
  
  /* Containers 
  .container-header {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-height: 80px;
  }
  .container-options {
    border-bottom: 1px solid #ebecf3;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 30px 0;
  }*/
  
  .container {
    display: flex;
    margin: 0 auto;
    max-width: 1300px;
    padding: 2rem 1rem;
  }
  
  .container-filters {
    flex: 1;
    margin-right: 60px;
    max-width: 260px;
  }
  
  .container-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 50px;
  }
  
  .container-results {
    flex: 3;
  }
  
  .container-options {
    border-bottom: 1px solid #ebecf3;
    display: flex;
    justify-content: left;
    margin-bottom: 30px;
    
  }
  
  .container-options .container-option:not(:first-child) {
    margin-left: 48px;
  }
  
  .container-options select {
    min-width: 100px;
  }
  
  .container-footer {
    margin: 4rem 0;
  }
  
  /* Styles the SFFV highlightings */
  
  em {
    font-style: normal;
  }
  
  em,
  mark {
    background: rgba(226, 164, 0, 0.4);
  }
  
  /* Clear refinements container */
  
  .clear-filters {
    align-items: center;
    display: flex;
  }
  
  .clear-filters svg {
    margin-right: 8px;
  }
  
  /* Panel */
  
  .container-body .ais-Panel {
    border-top: 1px solid #ebecf3;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  
  .ais-Panel-header {
    font-family: Hind, sans-serif;
  }
  
  /* Search box */

  .searchbelow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    padding-top: 20px;
    min-height: 32vh;

    
   
  
    
    
    margin: 0;
  
  }

  .searchbelow-background {

    background: azure;
    width: 100%;
    
  
  }


  .searchbelow .ais-SearchBox {
    height: 64px;
    width: 60%;
   

  }

  .search-page {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: left;
    height: auto;
    padding-top: 0;
    padding-left: 30px;;
    min-height: 20vh;
    margin: 0;
    

  }


  .search-page .ais-SearchBox {
    height: 64px;
    width: 60%;
  }

  .search-page .ais-SearchBox .ais-SearchBox-input {
    
    
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707039;
    border-radius: 16px;
    
    height: 64px;
    /*
      The "Hind" font family is vertically off-balance.
      Adding 4px of padding top makes it more vertically aligned.
    */
    
  }
  
  .facet-bar{
    display:flex;
  }

  .button-img{
    max-height: 20px;
  }
  .icon-button{
    display:flex;
    flex-direction: row;

 }
  





  


  .searchbelow .ais-SearchBox .ais-SearchBox-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    
border: 1px solid #70707039;
border-radius: 16px;
opacity: 1;
    font-family: Hind, sans-serif;
    height: 64px;
    /*
      The "Hind" font family is vertically off-balance.
      Adding 4px of padding top makes it more vertically aligned.
    */
    padding: 4px 48px 0 64px;
  }

  .search-filters-div {
    width:60%
  }

  .searchbelow-image {
    height: auto;
    max-height: 80%;
    max-width: 80%;
    
  }
  .searchbelow-img-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 150px;
    border-radius: 10px;
    justify-content: center;
    color: #555555;
    font-size: 12px;
    margin: 0;
    width: 300px;
    padding-top: 20px;
  
  

  }

  

  .searchabove {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: #FFFFFF;
    color: #555555;
    
    font-size: 12px;
    
  }


 
 


 

  .searchunder {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    justify-content: center;

  }

  .searchunder .ais-SearchBox .ais-SearchBox-input {

    font-family: Hind, sans-serif;
    height: 64px;
    /*
      The "Hind" font family is vertically off-balance.
      Adding 4px of padding top makes it more vertically aligned.
    */
    padding: 4px 48px 0 64px;
  }
  
  .header .ais-SearchBox {
    height: 64px;
    width: 740px;
    

  }
  
  .header .ais-SearchBox .ais-SearchBox-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707039;
    border-radius: 16px;
    
    font-family: Hind, sans-serif;
    height: 64px;
    /*
      The "Hind" font family is vertically off-balance.
      Adding 4px of padding top makes it more vertically aligned.
    */
    padding: 4px 48px 0 64px;
  }
  
  .header .ais-SearchBox-submit {
    padding: 0 1rem 0 2rem;
    width: 64px;
  }
  
  .header .ais-SearchBox .ais-SearchBox-input::placeholder {
    color: rgba(33, 36, 61, 0.24);
    opacity: 1; /* Firefox */
  }
  
  .ais-SearchBox-input:-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.24);
  }
  
  .ais-SearchBox-input::-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.24);
  }
  
  .ais-SearchBox-submit {
    color: #5B5B5B
  }
  
  .ais-RefinementList .ais-SearchBox-input {
    font-family: Hind, sans-serif;
    /*
      The "Hind" font family is vertically off-balance.
      Adding some padding top makes it more vertically aligned.
    */
    padding-top: 2px;
  }
  
  /* Hits  #21243d */
  
  .hit {
    color: #3c3c3d;
    font-size: 14px;
    line-height: 18px;
  }
  
  .hit h1 {
    font-size: 14px;
  }
  
  .hit-category {
    color: #21243d;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    margin-top: 12px;
    margin-bottom: 8px;
    opacity: 0.6;
    
  }
  
  .hit-description {
    margin-top: 2px;
  }
  
  .hit-info-container {
    overflow-wrap: break-word;
    word-break: break-word;
  }
  
  .hit-image-container {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
    margin: auto;
    width: 200px;
  }
  
  .hit-image {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
  
  .hit-em {
  
    font-size: 11px;
    font-weight: 600;
  }
  
  .hit-rating {
    border: 1px solid rgba(226, 164, 0, 0.5);
    border-radius: 4px;
    margin-left: 4px;
    padding: 0 4px;
  }
  
  .hits-empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 300px;
  }
  
  .hits-empty-state-title {
    font-family: Hind;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }
  
  .hits-empty-state-description {
    color: rgba(35, 37, 51, 0.6);
    font-size: 0.875rem;
    text-align: center;
  }
  
  .hits-empty-state .ais-ClearRefinements {
    margin-top: 1rem;
  }
  
  .hits-empty-state .ais-ClearRefinements-button--disabled {
    display: none;
  }
  
  .hits-empty-state .ais-ClearRefinements-button {
    background: rgba(10, 8, 41, 0.04);
    border-radius: 3px;
    color: #21243d;
    min-height: 48px;
    padding: 16px 24px;
  }

  .product-checkout{
    color: #3c3c3d;
  }
  
  /* ToggleRefinement */
  
  .ais-ToggleRefinement-label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  
  .ais-ToggleRefinement-checkbox {
    font: inherit;
    margin-left: 1rem;
    margin-right: 0;
    position: relative;
  }
  
  .ais-ToggleRefinement-checkbox:checked::before {
    color: #e2a400;
  }
  
  .ais-ToggleRefinement-checkbox::before {
    align-items: center;
    color: rgba(33, 36, 61, 0.32);
    content: 'No';
    display: flex;
    font-size: 0.8rem;
    height: 16px;
    position: absolute;
    right: 38px;
  }
  
  .ais-ToggleRefinement-checkbox:checked::before {
    content: 'Yes';
  }
  
  .ais-ToggleRefinement-count {
    display: none;
  }
  
  /* RatingMenu */
  
  .ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
    opacity: 0.5;
  }
  
  .ais-RatingMenu-starIcon {
    margin-right: 0.5rem;
  }

  .shop-info-div {

    max-width: 300px;
    text-align: center;
    
  
  }

  .navbar {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      height: 70px;
      align-items: center;
      
  }

  .navbar-contain {
    
    display: flex;
    color: #555555;
    align-items: center;
    height: 100%;
    min-width: 200px;
    
}
.nav-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676464;
  height: 100%;
}
.navbar-contain-left {
    
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  color: #555555;
  height: 100%;
  width: 100%;
  opacity: 80%;
}
.navbar-contain-right {
display: flex;
align-items: center;
justify-content: space-evenly;
font-weight: bold;
color: #555555;
height: 100%;
width: 100%;
opacity: 80%;
}



.navbar-contain-left:hover{
color: #4788C7;
border-left: 3.5px solid #4788C7;

}
.navbar-contain-right:hover{
color: #4788C7;



}

  .main-nav {
      display: flex;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
  }

  .main-nav li {
      margin: 0;
  }

  .nav-links {
      margin-left: 40px;
  }

  .logo {
      margin-top: 0;
  }

  .navbar-toggle {
      display: none;
  }

  .logo:hover,
  .nav-links:hover {
      color: rgba(255, 255, 255, 1);
  }

  .nav-button {
      display: flex;
      align-items: center ;
      justify-content: space-between;
      font-weight: bold;
      color: #555555;
      width: 200px;
      height: 85%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000005;
    border: 1px solid #655E5E24;
      border-radius: 10px;
      opacity: 1;
      
  }

 .nav-button-arrow{
  font-size: 22px;
  color: #4788C7

 }
 .nav-next-shop{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  color: #555555;
  height: 99%;
  width: 100%;
  opacity: 1;
  outline:none;
  border: none;
  background-color: #FAFAFA;


 }

 .nav-next-shop-left{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  color: #555555;
  height: 99%;
  width: 100%;
  opacity: 1;
  outline:none;
  border: none;
  background-color: #FAFAFA;

 }

 .nav-next-shop-left i,
 .nav-next-shop i{
  color: #4788C7
  
 }

 .nav-next-shop:hover{
  color: #4788C7;
  border-right: 1.5px solid #fafafa;
  cursor: pointer;
  
}
.nav-next-shop-left:hover{
  color: #4788C7;
  border-left: 1.5px solid #FAFAFA;
  cursor: pointer;
  
}
  
  /* Hide all mobile-specific design on desktop */
  
  @media (min-width: 900px) {
    [data-layout='mobile'] {
      display: none;
    }

    .hit-image-container {
      align-items: center;
      display: flex;
      height: 250px;
      justify-content: left;
      margin: auto;
      width: 250px;
      
    }
  

  




  }
  
  /* header button with transparent
   .header-button {
      width: 83px;
      height: 83px;
      background: #F7F8F9 0% 0% no-repeat padding-box;
      border: 8px solid #70707039;
      opacity: 1;
      border-radius: 50%;
  }

     .header-button {
      width: 90px;
      height: 90px;
      background: #F7F8F9 0% 0% no-repeat padding-box;
      border: 8px solid #70707039;
      opacity: 1;
      border-radius: 50%;
  }
*/