
.carousel .slide {
  
    background: #fff!important;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 2px solid #f3f3f3!important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: transparent!important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #cac9c9!important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #cac9c9!important;
}

.product-options em{
    background: none;

}


.section-product-info{
    flex: 1 1 auto;
    overflow: auto;
    padding: 1em 1em;
    color: #444;
    

}

.product-info-wrapper{
    display: flex;
   width: 100%;
   flex-direction: row;
   padding-top: 50px ;

   

}


.product-details{
    width:600px;
    background-color: white;
    display: block;
    box-sizing: inherit;
    height: 60vh;
    margin-left: 30px;
    margin-top: 40px;
    
    
}

.product-details li {
    list-style-type: disc;
    color:#67676b;
}

.product-details h6 {
    font-size: 1em;
    margin-block-end: 1em


}

.product-images-small{
  
    background-color: white;
    display: block;
    box-sizing: inherit;
    height: 60vh;
    padding-left: 10px;
    padding-right: 10px;
    
}


.product-image-small-div{
  
    
    display: block;
    box-sizing: inherit;
    height: 80px;
    width: 80px;
    margin-bottom: 12px;

   
    
}

.product-image-small{
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #EBEBEB;


}



.product-image-container{
  
    width: 550px;
    height: 550px;
    margin-left: 20px;
    
}

.product-image{
    height: auto;
    max-width: 100%;
    


}

.image___xtQGH.carousel__image.carousel__image--success.product-image {
    height: auto;
    max-width: 100%;
}

.slideInner___2mfX9.carousel__inner-slide div {
    outline: none;
    
  }

.horizontalSlider___281Ls.carousel__slider.carousel__slider--horizontal.slider-images{

    height: auto;
    max-width: 100%;


}





.checkout-button{
    margin-top: 1rem;
    width: 300px;
}

.product-subheader{
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    color: #767474;
}

.product-subheader-price{

    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    

}

.quantity-button{
    padding-top: 10px;

}

.quantity-header-top{

    margin-bottom: 0.5rem;
    margin-top: 0.8rem;

}

.quantity-header-bottom{

    margin-bottom: 0.5rem;
    margin-top: 0.6rem;
    color: #767474;

}

.ship-label{
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
}


@media (max-width: 899px) {


    .slider-and-buttons{
        position: relative;
    }

    .buttonBack___1mlaL.carousel__back-button.slider-back{
        position: absolute;
        top: 45%;
        transform: translateY(-45%);
        color: #d0d0d0;
        outline:none;
        border: none;
        background-color: transparent;

        
    }

    .buttonNext___2mOCa.carousel__next-button.slider-next{
        position: absolute;
        top: 45%;
        right:0;
        transform: translateY(-45%);
        color: #d0d0d0;
        outline:none;
        border: none;
        background-color: transparent;

    }
    

   

    .product-images-small{
        display: none;
    }

    .product-info-wrapper{
        display: block;
       flex-direction: column;
       padding-top: 0;
    
       
    
    }

    .product-image{
        height: auto;
        max-width: 100%;
        
    
    
    }

    .product-image-container {
        margin-top: 20px; 
        width: auto; 
     height: auto; 
    margin-left: 0;
    margin-bottom: 10px; 
    }


    .product-details {
        width: auto; 
        background-color: white;
        display: block;
        box-sizing: inherit;
        height: auto;
        margin-bottom: 10vh; 
        margin-left: 0;
        margin-right: 0;
        margin-top:0;

      
    }

    .checkout-button{
        margin-top: 1.2rem;
        width: 100%;
    }


    .section-product-info {
        flex: 1 1 auto;
        overflow: auto;
        color: #444;
        padding: 1.2em 1.2em;
    }


    .wrapper {
        display: flex;
        height: auto;
        min-width: 100vw;
       
    }

    .product-options{
        display: block;
        max-width: 90vw;
    }






}
