
.page-wrapper {
  min-height: 100vh;;
  display: flex;
}

.side-nav {
  min-height: 100vh;;
}

.menu-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  height: 100vh;
}
.nav-side { 
  flex: 0 0 auto;
  display: flex;
  min-width: 90px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center; 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-right: 1px solid #7070701F;
  opacity: 1;
}


 
  .nav-side a {
    color: #555555;
    
  
  }

  .nav-side a:hover {
    color: #000000;
    
  }

  .active-nav-icon {
  
    
    border-right: 2px solid #4788C7;
    border-left: 1.5px solid #FFFFFF;

    
    width: 100%;

    display: flex;
    justify-content: center;


  }
  .active-nav-icon i {

    color:  #3C3C3C;

  }
  
  .inactive-icon-div {

    display: flex;
    justify-content: center;
    font-size: 1.1em;
    align-items: center;
 
    
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    

  

  }

  .page-header-text{
    margin-left:30px;
  }
  .header-text-indent{
    padding:14px;
    
  }
  .page-header-sub{
    margin-top:3px;
  }

  
 
  .logout-icon-div {
    position: absolute;
    bottom: 0;
    transform: translateY(-10%);
    display: flex;
    justify-content: center;
    font-size: 1.1em;

    
    padding-top: 15px;
    padding-bottom: 15px;
    color: #555555;
    
    

  

  }

  
  




  .logout-hover:hover {
    color: #000000;
    cursor: pointer;

  }
  .card-wrapper {
    padding: 30px;
  }

 .stores-counter {
   font-size: 0.9rem;
 }
 
  
  .section-main {
    flex: 1 1 auto;
    overflow: auto;
    padding: 1em 1em;
    color: #444;
  }

  .section-main-discover {
    flex: 1 1 auto;
    overflow: auto;
    padding: 1em 1em;
    color: #444;
    background-color: #FAFAFA;
  }

  .section-shop {
    flex: 1 1 auto;
    overflow: auto;
    color: #444;
  }



  .card-image-container {
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
  }
  
  .card-image {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .side-logo {
    height: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .login-logo {

    max-height: 20px;

  }
  .login-logo-div {

    padding: 10px;

  }

  .login-h1 {
    color: #555555;
  }

  .login-page{
    background-color: #F7F8F9;
    min-height: 100vh;
  }
  
  .side-icon-div {
    padding: 15px;
    width: 60%;
    display: flex;
    justify-content: center;
    font-size: 1.1em;
  
  }

  

.side-push {
  margin:0
}

.logo-header {
  max-height: 60px ;
  padding: 20px;
}

.coll-button-group {
  margin-top:20px;
  margin-left:30px;
  
}

.home-page-div {

  background-color: #FAFAFA;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

 



}

.home-page-div body {

  background-color: #FAFAFA;
 
}

.home-page-header {
  display: flex;
  flex-direction: row;
  padding: 30px;
  align-items: center;
  justify-content: space-between;


  
}





.home-page-hero {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  



  align-items: center;
  min-height: 400px;
  
}



.home-page-card {

  
  
  
height: 500px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}



.logo-circle {
  background-color: #FFFFFF;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #7070701F;

  
 
  

}

.logo-circle-1 {
  background-color: #FFFFFF;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #7070701F;
  position: relative;
  top: 170px;
  right: 60px;
  

}

.logo-circle-2 {
  background-color: #FFFFFF;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #7070701F;
  position: relative;
  top: 50px;
  left: -20px;
  

}
.svg-circle {
  position: absolute;
  right: -200px;
  top: -200px;
  z-index: 0;
  min-width: 1000px;
}


.home-page-card ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.home-page-card ul {
  position: relative;
  display: flex;
  width: 500px;
  flex-wrap: wrap;
}

.home-page-card li {
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 140px;
  height: 140px;
}

.test-li {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #7070701F;
  box-shadow: 0px 3px 6px #00000005;


}

.test-li img {
  max-width: 80%;
}




.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home-page-works {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 70px;
}

.home-page-works-card-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}

.home-page-works-card {
  display: block;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 20px;
  text-align: center;

 


}


.home-page-works-icon {
  max-height: 40px;
}

.home-page-why-div {
  margin-top: 90px;
  min-height: 400px ;
  display: flex;
  justify-content: center;

}

.home-page-why-img {
  width: 400px;
  height: 254px;
  background-color: #ffffff;
  border: 1px solid #7070701F;
  border-radius: 16px;
  overflow: hidden;
 display: flex;
align-items: center;
flex-direction: column;
margin-right: 150px;
box-shadow: 0px 3px 6px #00000005;




}

.home-page-why-text {
  width: 400px;
  height: 254px;
 display: flex;
align-items: center;
word-wrap: normal;



}

.home-page-why-img-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15%;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  text-align: center;
  color: #676464;
  border-bottom: 1px solid #7070701F;


  
}

.home-page-why-embed {
  max-width: 100%;
  height:auto;
  
}

.home-page-why-img-bar i {
  color: #4788c7;
  font-size: 1.2rem ;
  
}

.shop-now {
  margin-top: 5px;
  font-size: 1rem;
}

.shop-now i {
  margin-left: 5px;
  font-size: 1rem;
  color: #4788c7;
}

.shop-now a {
 
  color: #00000099;
}

.home-page-why-img-2 {

margin-left: 100px;
padding-bottom: 20px;




}

.home-page-why-div-2 {
  margin-top: 20px;
  min-height: 400px ;
  display: flex;
  justify-content: center;

}

.home-page-why-text-2 {
  width: 400px;
  height: 254px;
 display: flex;
align-items: center;
word-wrap: normal;
margin-top: 10px;
margin-right: 50px;
}

.shop-now-2 i {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #4788c7;
}

.shop-now-2 a {
 
  color: #00000099;
}

.shop-now-2 {
  margin-top: 5px;
  font-size: 1rem;
}


.feed-images {
  width : 100%;
  height: auto;
}

.ui.header.home-page-head{
  font-size: 3em;
  color: #424242;

}







@media (max-width: 899px) {
  
  .home-page-hero {
    flex-direction: column;
    min-height: 300px;
    margin-top: 30px;
  }

  .home-page-why-div {
    flex-direction: column;
    align-items: center;

  }

  .home-page-why-img {
    margin-right: 0px;
  }


  .home-page-why-div-2 {
    flex-direction: column-reverse;
    align-items: center;

  }

  .home-page-why-text-2 {

    margin-right: 0px;
    margin-top: 0px;
    height: 175px;
  }

  .home-page-why-img-2 {
    margin-left: 0px;

  
  }

  

  
  .ui.header.home-page-head{
    font-size: 2em;
    color: #424242;
    text-align: center;
    
  
  }

  .home-page-why-text,
  .home-page-why-text-2,
  .home-page-why-img,
  .home-page-card,
  .home-page-hero-div  {
    width:90vw;

  }

  .home-page-card {
    display: none;
    

  }

  


  .home-page-hero-div {
    justify-content: center;
    align-content: center;
  }

  .home-page-buttons {
    display: flex;
    justify-content: center;
  }

  .sub.header.header-sub {
    font-size: 0.8rem !important;
  }

  .home-page-works {
    margin-top:40px;
  }

  .home-page-why-div {
    margin-top:50px;
  }

  input, select, textarea {
    font-size: 16px !important;
}








 
  


  






}
